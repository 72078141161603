var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loader" },
    [
      _c(
        "b-container",
        { staticClass: "home-container" },
        [
          _c("b-row", [_c("b-col", [_c("div", { staticClass: "logo" })])], 1),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-center pt-5", attrs: { sm: "12" } },
                [
                  _c("div", {
                    staticClass: "spinner spinner-border spinner-border-sm",
                    attrs: { role: "status", "aria-hidden": "true" }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }