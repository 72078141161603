<template>
  <div class="loader">
    <b-container class="home-container">
      <b-row>
        <b-col>
          <div class="logo"></div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center pt-5" sm="12">
          <div
            class="spinner spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'loader'
}
</script>
<style lang="scss" scoped>
@import '@/styles.scss';

.loader {
  height: 667px;
  color: #fff;
  background-image: url('../assets/img/evenir-small.png');
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;

  .r {
    height: 50%;
    overflow: hidden;
  }

  .logo {
    width: 120px;
    height: 110px;
    margin: 180px auto 0;
    background-image: url('../assets/img/logo.png');
  }

  hr {
    border-color: #fff;
    width: 15%;
  }

  h1 {
    font-size: 20px;
    font-weight: $font-weight-bold;
    margin-top: 24px;
  }

  .btn {
    width: 100%;
  }

  .spinner {
    width: 50px;
    height: 50px;
  }
}

.home-container {
  max-width: $inner-content-max-width-s;
}

@include media-breakpoint-up(sm) {
  .loader {
    background-image: url('../assets/img/evenir-small.png');
  }
}

@include media-breakpoint-up(md) {
  .home {
    background-image: url('../assets/img/evenir-small@2x.png');
  }
}

@include media-breakpoint-up(lg) {
  .home {
    background-image: url('../assets/img/evenir-large.png');
  }
}

@include media-breakpoint-up(xl) {
  .home {
    background-image: url('../assets/img/evenir-large@2x.png');
  }
}
</style>
